import React from 'react';
import logo from './logo.svg';
import './App.css';

type SearchRecord = {
  node: {
    id: string;
    text: string;
    metadata: {
      title: string;
      theme: string;
      slug: string;
    }
  }
}

function App() {
  const [loading, setLoading] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [top_k, setTop_k] = React.useState(10);
  const [searchResults, setSearchResults] = React.useState([]);
  const functionEndpoint = process.env.REACT_APP_FUNCTION_ENDPOINT || 'http://localhost:7071/api/search';

  const search = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    fetch(`${functionEndpoint}?query=${searchQuery}&top_k=${top_k}`)
      .then((response) => response.json())
      .then((data) => {
        setSearchResults(data);
        setLoading(false);
      });
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <input type="text" placeholder="Search..." onChange={(event) => setSearchQuery(event.target.value)} />
        <button onClick={search}>Search</button>
        {loading && <p>Loading...</p>}
        <ul>
          {searchResults.map((record: SearchRecord, index) => {
            const HTML = { __html: record.node.text };
            const title = record.node.metadata.title + "<br>"
            const url = `https://volksverpetzer.de/${record.node.metadata.theme}/${record.node.metadata.slug}`
            HTML.__html = title + HTML.__html;
            return (
              <li key={index} style={{ listStyleType: "none" }} onClick={() => { window.open(url) }}>

                <div className='node' style={{ maxWidth: "800px", width: "80%" }} dangerouslySetInnerHTML={HTML}></div>

              </li>
            )
          })}
        </ul>
      </header>
    </div>
  );
}

export default App;
